import dynamic from 'next/dynamic'
import { api } from '../config'

const Footer = dynamic(() => import('../components/Footer'))
const Header = dynamic(() => import('../partials/Head'))
const HomePage = dynamic(() => import('../components/Home'))
const News = dynamic(() => import('../components/News/News'))
const Newsletter = dynamic(() => import('../components/Newsletter/Newsletter'))

export default function Home({ getTerminals }) {
  return (
    <main className='bg-white'>
      <Header />
      <HomePage getTerminals={getTerminals} />
      {/* <News /> */}
      <Newsletter />
      <Footer />
    </main>
  )
}

export async function getStaticProps() {
  const data = await api.get('/Terminals/GetFromTerminals')
  const getTerminals = await data.data

  if (!getTerminals) {
    return {
      notFound: true
    }
  }
  return {
    props: {
      getTerminals
    }, // will be passed to the page component as props
    revalidate: 60
  }
}
